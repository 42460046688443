import * as actionTypes from '../../types'
import axios from 'axios'


function requestAnnoucements(){
    return {
        type: actionTypes.REQUEST_ANNOUNCEMENTS
    }
}


function announcementError(payload){
    return {
        type: actionTypes.ANNOUNCEMENTS_ERROR,
        error: payload
    }
}

function anouncementSuccess(payload){
    return {
        type: actionTypes.SUCCESS_ANNOUNCEMENTS,
        anouncements: payload
    }
}


export function fetchAnnouncements() {

    return async function(dispatch){
        dispatch(requestAnnoucements());

        try {

            let { data } = await axios(`/api/api/v1/announcement-get/`);

            dispatch(anouncementSuccess(data))

        }
        catch(err){
            console.log(err)
            dispatch(announcementError(err))
        }
    }

}