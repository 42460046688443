import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './LandingPageStyles';
import dynamic from 'next/dynamic';
import Link from 'next/link';


const CommonButton = dynamic(() => import('../../../shared/Components/Button/CommonButton'), {
    ssr: false,
})

function Intro({ matches }) {
    const classes = useStyles();
    return (
        <Grid container>

            <Grid item xs sm lg>
                <Typography
                    variant="h2"
                    component="h2"
                    className={
                        !matches ? classes.PopularHeading : classes.popularMob
                    }
                >
                    You Are Not Alone
                </Typography>

                <Typography
                    variant="h1"
                    component="h1"
                    className={classes.content}
                >
                   write your hacks
                </Typography>
                <Typography
                    variant="h2"
                    component="h2"
                    className={`${classes.content} ${classes.margin0}`}
                >
                    <Link href="/StartWriting">
                        <a className={classes.link}>
                            <CommonButton
                                variant="contained"
                                className={classes.btn}
                                color="primary"
                            >
                                Write Your Story
                    </CommonButton>
                        </a>
                    </Link>
                </Typography>
                <Typography
                    variant="subtitle2"
                    component="h6"
                    className={`${classes.color} ${classes.margin5}`}
                >
                    <i>No Login. No user id</i>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Intro
