/**======================================================================
 * Homepage Action Types
 =======================================================================*/

//announcements action types
export const REQUEST_ANNOUNCEMENTS = 'REQUEST_ANNOUNCEMENTS'
export const SUCCESS_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS'
export const ANNOUNCEMENTS_ERROR = 'ANNOUNCEMENTS_ERROR' 

//firstTen Article Actions
export const REQUEST_STARTING_TEN_ARTICLES = 'REQUEST_STARTING_TEN_ARTICLES'
export const SUCCESS_STARTING_TEN_ARTICLES = 'SUCCESS_STARTING_TEN_ARTICLES'
export const STARTING_TEN_ARTICLES_ERROR = 'STARTING_TEN_ARTICLES_ERROR' 

//filter article actions
export const FILTER_REQUEST = 'FILTER_REQUEST'
export const FILTER_BY_NEWEST = 'FILTER_BY_NEWEST';
export const FILTER_BY_MINVIEWS = 'FILTER_BY_MINVIEWS';
export const FILTER_BY_MAXVIEWS = 'FILTER_BY_MAXVIEWS' 
export const FILTER_ERROR = 'FILTER_ERROR'
export const FILTER_TYPE='FILTER_TYPE'


/**======================================================================
 * Take A Break Action Types
 =======================================================================*/

export const REQUEST_TIMER_INTERVAL = 'REQUEST_TIMER_INTERVAL'
export const REQUEST_TAKEBREAK_DATA = 'REQUEST_TAKEBREAK_DATA'
export const TAKEBREAK_DATA_SUCCESS = 'TAKEBREAK_DATA_SUCCESS'
export const TAKEBREAK_DATA_ERROR = 'TAKEBREAK_DATA_ERROR'


export const REQUEST_KEYWORDS = 'REQUEST_KEYWORDS'
export const REQUEST_KEYWORDS_SUCCESS = 'REQUEST_KEYWORDS_SUCCESS'
