import React from 'react';
import Link from 'next/link';

const RedirectIcon = (props) => {

    return (
        <div style={{ position: "relative", left: "-11px", top: "7px" }}>
            <Link href={props.link} prefetch={false}>
                <a>
                    <img src={'/img/redirect2.png'} loading="lazy" width='14' height='14'/>
                </a>
            </Link>
        </div>
    );
}

export default RedirectIcon;