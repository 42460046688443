import React from 'react'
import Grid from '@material-ui/core/Grid';
import Header from './Header'
import ArticleCard from '../Components/MainCard/Card';
import { useStyles } from './styles'
import RedirectIcon from '../../../shared/Components/redirectIcon';
import Link from 'next/link';

function BestPicks(props) {
    const classes = useStyles();

    let renderArticleData = [];
    if (props.bestPicksArticles && props.bestPicksArticles.length!==0) {
        for (let i = 0; i < props.bestPicksArticles.length; i++) {
                renderArticleData[i] = (
                    <ArticleCard
                            key={i}
                            borderColor='rgb(16, 174, 79)'
                            bgColor="#1F2022"
                            {...props.bestPicksArticles[i]}
                        />
                )
        }
    }

    return (
        <Grid item lg={12} xs={12} md={12}>
            <div className={classes.flex}>
                <Link href={'/best-posts'} prefetch={false}>
                    <a className={classes.link}>
                <Header
                    header="Best Picks"
                        />
                    </a>
                </Link>
                <RedirectIcon link="/best-posts" /> { /** seperate in constants */}
            </div>
            {renderArticleData}
        </Grid>
    )
}

export default BestPicks;