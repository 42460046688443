import * as actionTypes from '../../types'
import axios from 'axios';

export function requestFilter(){
    return {
        type: actionTypes.FILTER_REQUEST
    }
}

export function filterByNewest(payload){
    return {
        type: actionTypes.FILTER_BY_NEWEST,
        newestArticles: payload.results,
        next: payload.next
    }
}

export function filterByMinViews(payload){
    return {
        type: actionTypes.FILTER_BY_MINVIEWS,
        minViewedArticles: payload.results,
        next: payload.next
    }
}

export function filterByMaxViews(payload){
    return {
        type: actionTypes.FILTER_BY_MAXVIEWS,
        maxViewedArticles: payload.results,
        next: payload.next
    }
}

export function filterError(payload){
    return {
        type: actionTypes.FILTER_ERROR,
        error: payload
    }
}

export function setFilterAction(filterBy){
    console.log(filterBy)
    return {
        type: actionTypes.FILTER_TYPE,
        filterBy: filterBy,
        next: ''
    }
}


export function successFirstTenArticles(payload){
    return {
        type: actionTypes.SUCCESS_STARTING_TEN_ARTICLES,
        articles: payload
    }
}

export function errorFirstTenArticles(payload){
    return {
        type: actionTypes.STARTING_TEN_ARTICLES_ERROR,
        error: payload
    }
}

export function chooseBetweenFilters(choice){
    console.log(`calling ${choice}`)
    return function(dispatch) {
        switch(choice){
        
            case 10: 
                dispatch(setFilterAction("Newest"))
                break;
    
            case 20: 
                dispatch(setFilterAction('MaxViews'))
                break;
    
            case 30: 
                dispatch(setFilterAction('MinViews'))
                break;

            default: dispatch(setFilterAction('Newest'))
        
        }
    }
    
}


export function fetchFirstTenArticles (){

    return async function(dispatch){
        dispatch(requestFilter());
        try {

            let { data } = await axios(`/api/api/v1/first-ten-article/`);
    
    
            dispatch(successFirstTenArticles(data));
    
        }
        catch(e){
            console.log(e)
            dispatch(errorFirstTenArticles(e))
        }
    }

}


//async function for filtering newest articles
export function fetchNewestArticles(next) {
    return async function(dispatch){
        
        dispatch(requestFilter());

        let url = next !== '' ? next : `/api/api/v1/after-ten-article/?limit=7&offset=0`
        
        try { 
            let { data } = await axios(url)

            dispatch(filterByNewest(data))
        }
        catch(e){
            console.log(e)
            dispatch(filterError(e))
        }
    }
}

//async function for filtering Min Views articles
export function fetchMinViewsArticles(next) {
    return async function(dispatch){
        
        dispatch(requestFilter())
        
        console.log(next)
        let url = next ? next : `/api/api/v1/filter-by-lowest-numofviews/?limit=7&offset=7`
        console.log(url)


        try { 
            let { data } = await axios(url)

            dispatch(filterByMinViews(data))
        }
        catch(e){
            console.log(e)
            dispatch(filterError(e))
        }
    }
}

//async function for filtering Max Views articles
export function fetchMaxViewsArticles(next) {
    return async function(dispatch){
        
        dispatch(requestFilter())
        console.log(next)
        let url = next ? next : `/api/api/v1/middle-section-article/?limit=7&offset=7`
        console.log(url)

        try { 
            let { data } = await axios(url)

            dispatch(filterByMaxViews(data))
        }
        catch(e){
            console.log(e)
            dispatch(filterError(e))
        }
    }
}